/** Alpine */
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

/** Splide */
import '@splidejs/splide/dist/css/splide.min.css'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'

import Splide from '@splidejs/splide'

document.addEventListener('DOMContentLoaded', function(event) {
    const splideElement = document.getElementsByClassName('splide').length > 0

    if (splideElement) {
        new Splide('.splide', {
            type: 'loop',
            autoplay: true,
            lazyLoad: 'nearby',
            pagination: false,
            arrows: true
        }).mount()
    }
})

/** Form validation */
document.addEventListener('DOMContentLoaded', function(event) {
    /* Format phone number as user types*/
    const isNumericInput = (event) => {
        const key = event.keyCode
        return ((key >= 48 && key <= 57) || (key >= 96 && key <= 105))
    }

    const isModifierKey = (event) => {
        const key = event.keyCode

        return (event.shiftKey === true || key === 35 || key === 36) || (key === 8 || key === 9 || key === 13 || key === 46) || (key > 36 && key < 41) || (
            (event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    }

    const enforceFormat = (event) => {
        if (!isNumericInput(event) && !isModifierKey(event)) {
            event.preventDefault()
        }
    }

    const formatToPhone = (event) => {
        if (isModifierKey(event)) { return }

        const input = event.target.value.replace(/\D/g,'').substring(0,10)
        const areaCode = input.substring(0,3)
        const middle = input.substring(3,6)
        const last = input.substring(6,10)

        if (input.length > 6) {
            inputElement.value = `(${areaCode}) ${middle}-${last}`
        } else if (input.length > 3) {
            inputElement.value = `(${areaCode}) ${middle}`
        } else if (input.length > 0) {
            inputElement.value = `(${areaCode}`
        }
    }

    const inputElement = document.getElementById('phone')

    if (inputElement) {
        inputElement.addEventListener('keydown', enforceFormat)
        inputElement.addEventListener('keyup', formatToPhone)
    }

    /* Validate form function */
    const validateForm = (event) => {
        /* Check if the phone number has the correct number of digits */
        if (inputElement.value.length < 14) {
            event.preventDefault()
            document.getElementById('form-error').classList.remove('hidden')
        } else {
            /* Check if all the fields are complete */
            let name = document.forms['form']['fullName'].value
            let email = document.forms['form']['email'].value
            let buyer = document.querySelector('input[name="fields[buyer]"]:checked')
            let seller = document.querySelector('input[name="fields[seller]"]:checked')

            //if (name == null || email == null || buyer == null || seller == null) {
                //event.preventDefault()
                //document.getElementById('form-error').classList.remove('hidden')
            //} else {
                document.getElementById('form-error').classList.add('hidden')
            //}
        }
    }

    /* Validate form before submitting form */
    const buttonElement = document.getElementById('submit-lead')

    if (buttonElement) {
        buttonElement.addEventListener('click', validateForm)
    }
})
